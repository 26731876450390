.App {
  display: flex;
  flex-direction: column;
}

body,
html,
#root,
.App {
  height: 100%;
  overflow-x: hidden !important;
}

::-webkit-scrollbar {
  display: none;
}
